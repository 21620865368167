import { getNameInitials, getRandomColorFromString } from "@/utilities";
import { Avatar as AntdAvatar, AvatarProps, Checkbox, Tooltip } from "antd";
import { FC, memo, useRef } from "react";
import useIsHovered from "@/hooks/useIsHovered";

type Props = Omit<AvatarProps, "src"> & {
  name?: string;
  useToolTip?: boolean;
  checked?: boolean;
  onCheck?: () => void;
  src?: string;
};
const IMAGE_STYLES: React.CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};
const CustomAvatarComponent: FC<Props> = ({
  name = "",
  useToolTip = false,
  style,
  checked,
  onCheck,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovered = useIsHovered(ref);

  const img = rest?.src ? <img src={rest.src} style={IMAGE_STYLES} /> : null;

  const avatar = (
    <AntdAvatar
      alt={name}
      size="small"
      style={{
        backgroundColor: rest?.src
          ? "transparent"
          : getRandomColorFromString(name),
        display: "flex",
        alignItems: "center",
        border: "none",
        // objectFit: "contain",
        ...style,
      }}
      {...rest}
      src={img}
    >
      {getNameInitials(name)}
    </AntdAvatar>
  );

  if (useToolTip) {
    return <Tooltip title={name}>{avatar}</Tooltip>;
  }

  return (
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      {isHovered && onCheck && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
            borderRadius: 12,
            zIndex: 1,
          }}
          onClick={onCheck}
        >
          <Checkbox
            checked={checked}
            style={{
              ...style,
            }}
          />
        </div>
      )}
      {avatar}
    </div>
  );
};

export const CustomAvatar = memo(
  CustomAvatarComponent,
  (prevProps, nextProps) => {
    return prevProps.name === nextProps.name && prevProps.src === nextProps.src;
  }
);
