import LazyLoad from "@/components/lazy-load";
import { PlaceHolderPage } from "@/pages/placeholder";
import { Outlet, Route } from "react-router-dom";

const getReportsRoutes = () => {
  return (
    <Route
      path="/reports"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/reports/list").then((module) => ({
              default: module.ReportsListPage,
            }))
          }
        >
          <Outlet />
        </LazyLoad>
      }
    >
      <Route
        path="create"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/reports/create").then((module) => ({
                default: module.ReportsCreatePage,
              }))
            }
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/reports/edit").then((module) => ({
                default: module.ReportsEditPage,
              }))
            }
          />
        }
      />
    </Route>
  );
};

const getIntegrationsRoutes = () => {
  return (
    <Route path="/connections" element={<Outlet />}>
      <Route
        path="catalog"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/components/connections/catalog").then((module) => ({
                default: module.CatalogPage,
              }))
            }
          />
        }
      />
    </Route>
  );
};

const getCompanySettingsRoutes = () => {
  return (
    <Route
      path="/administration/settings"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/admin/settings/show").then((module) => ({
              default: module.CompanySettingsPage,
            }))
          }
          componentProps={{ isSuperAdmin: true }}
        >
          <Outlet />
        </LazyLoad>
      }
    >
      <Route
        path="team/edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/team/edit").then((module) => ({
                default: module.EditPersonPage,
              }))
            }
            componentProps={{ return_resource: "settings" }}
          />
        }
      />
    </Route>
  );
};

const getLogsRoutes = () => {
  return (
    <Route
      path="/administration/logs"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/admin/logs/show").then((module) => ({
              default: module.CompanyLogsPage,
            }))
          }
        />
      }
    />
  );
};

const getContactRoutes = () => {
  return (
    <>
      <Route
        path="/contacts/show/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/contacts/show").then((module) => ({
                default: module.ContactShowPage,
              }))
            }
          />
        }
      />
      <Route
        path="/contacts/edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/contacts/show").then((module) => ({
                default: module.ContactShowPage,
              }))
            }
          />
        }
      />
    </>
  );
};

const getCompaniesRoutes = () => {
  return (
    <Route
      path="/companies/show/:id"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/companies/show").then((module) => ({
              default: module.CompanyShowPage,
            }))
          }
        />
      }
    />
  );
};

const getReferralLinksRoutes = (isSuperAdmin: boolean) => {
  return (
    <Route
      path="/referral-links"
      element={
        isSuperAdmin ? (
          <LazyLoad
            getComponent={() =>
              import("@/pages/referral-links/list").then((module) => ({
                default: module.ReferralLinkList,
              }))
            }
          >
            <Outlet />
          </LazyLoad>
        ) : (
          <PlaceHolderPage />
        )
      }
    >
      <Route
        path="create"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/referral-links/create").then((module) => ({
                default: module.ReferralLinkCreate,
              }))
            }
            componentProps={{
              action: "create",
            }}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/referral-links/create").then((module) => ({
                default: module.ReferralLinkCreate,
              }))
            }
            componentProps={{
              action: "edit",
            }}
          />
        }
      />
    </Route>
  );
};

export const getCommonRoutes = ({
  isSuperAdmin,
}: {
  isSuperAdmin: boolean;
}) => {
  return (
    <>
      {/* Administration */}
      {getReferralLinksRoutes(isSuperAdmin)}
      {getCompanySettingsRoutes()}
      {getLogsRoutes()}
      {getIntegrationsRoutes()}
      {getReportsRoutes()}
      {/* Hidden in left nav */}
      {getCompaniesRoutes()}
      {getContactRoutes()}
    </>
  );
};
