import { useCallback, useEffect, useRef, useState } from "react";

type OptionsProps = {
  delay?: number;
};

const useIsHovered = (ref: React.RefObject<any>, options?: OptionsProps) => {
  /*
    NOTE: to use the isTransitioning, you'll want to provide a transitionPropertyName
    that matches the transition you want to listen to. Additionally, specify that prop
    property in your transition like transition: opacity 250ms ease-in-out;
   */

  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(false);

  const handleMouseEnter = useCallback(() => {
    timeoutRef.current = true;
    setTimeout(() => {
      if (timeoutRef.current) setIsHovered(true);
    }, options?.delay || 0);
  }, [options?.delay]);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = false;
    setIsHovered(false);
  }, []);

  useEffect(() => {
    if (!ref?.current) return;

    const node = ref.current;
    node.addEventListener("mouseenter", handleMouseEnter);
    node.addEventListener("mouseleave", handleMouseLeave);

    // Cleanup
    return () => {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [ref, handleMouseEnter, handleMouseLeave]);

  return isHovered;
};

export default useIsHovered;
