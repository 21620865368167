import { FullScreenLoading } from "@/components";
import { AppModeContext, AppModeContextProvider } from "@/contexts/app-context";
import { ColorModeContextProvider } from "@/contexts/color-mode";
import { API_BASE_URL } from "@/providers";
import { customTitleHandler } from "@/providers/customHeader";
import globalAxiosInstance from "@/providers/globalAxiosProvider";
import {
  common_resources,
  company_resources,
  partner_resources,
  super_admin_resources,
} from "@/resources";
import { getRoutes } from "@/routes";
import type { Identity } from "@/types";
import { useAuth, useClerk, useUser } from "@clerk/clerk-react";
import { useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { AuthProvider, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { App as AntdApp } from "antd";
import LogRocket from "logrocket";
import { useContext, useEffect, useMemo } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import { getEnvironmentVariable } from "./utilities/build-utils";

function getSuperAdminPrivilege(user: ReturnType<typeof useUser>["user"]) {
  return (
    user?.primaryEmailAddress?.emailAddress?.endsWith("@propelgtm.com") ||
    user?.primaryEmailAddress?.emailAddress === "tony3dong@gmail.com" ||
    user?.primaryEmailAddress?.emailAddress === "tonydong91@gmail.com"
  );
}

function getResources({
  mode,
  isSuperAdmin,
}: {
  mode: string;
  isSuperAdmin: boolean;
}) {
  let resources = mode === "Company" ? company_resources : partner_resources;
  resources = resources.concat(common_resources);
  if (isSuperAdmin) {
    resources = resources.concat(super_admin_resources);
  }
  return resources;
}

function getAuthProvider(
  getToken: ReturnType<typeof useAuth>["getToken"],
  signOut: ReturnType<typeof useClerk>["signOut"],
  user: ReturnType<typeof useUser>["user"],
  isSignedIn: boolean | undefined
): AuthProvider {
  return {
    login: async () => {
      const token = await getToken();
      if (!token) {
        return {
          success: false,
        };
      }
      return {
        success: true,
        redirectTo: "/",
      };
    },

    logout: async () => {
      await signOut();
      return {
        success: true,
        redirectTo: "/",
      };
    },

    onError: async (error) => {
      console.error(error);
      if (error && error.response && error.response.status === 403) {
        await signOut();
        return {
          success: true,
          redirectTo: "/",
        };
      }

      return { error };
    },

    check: async () => {
      try {
        const token = await getToken();
        if (!token) {
          return { authenticated: false };
        }

        // Ping the API for verification
        await globalAxiosInstance.post(`${API_BASE_URL}/login`);

        // Clerk's `useUser` gives access to user details
        const email = user?.primaryEmailAddress?.emailAddress;

        // LogRocket initialization for certain users
        if (
          !email?.endsWith("@propelgtm.com") &&
          email !== "tonydong91@gmail.com" &&
          email !== "tony3dong@gmail.com"
        ) {
          if (import.meta.env.MODE === "production") {
            const nullCheckedEmail = email ?? "";
            LogRocket.identify(nullCheckedEmail, {
              name: user?.fullName ?? "",
              email: nullCheckedEmail,
            });
          }
        }

        return {
          redirectTo: "/",
          authenticated: true,
        };
      } catch (error: any) {
        await signOut(); // Log out in case of an error
        return {
          authenticated: false,
          error: new Error(error),
          redirectTo: "/login",
          logout: true,
        };
      }
    },

    getPermissions: async () => null,

    getIdentity: async (): Promise<Identity> => {
      if (isSignedIn && user) {
        return user;
      }
      return null;
    },
  };
}

const RefineWrapper: React.FC = () => {
  const { getToken } = useAuth(); // Clerk's token retrieval method
  const { signOut } = useClerk(); // Using `useClerk` to log out
  const { user, isSignedIn } = useUser(); // Using `useClerk` to log out
  const { mode } = useContext(AppModeContext);

  useEffect(() => {
    globalAxiosInstance.interceptors.request.clear();
    globalAxiosInstance.interceptors.request.use(async (config) => {
      const token = await getToken();
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Mode"] = mode;
      }

      return config;
    });
  }, [getToken, mode]);

  const authProvider: AuthProvider = useMemo(
    () => getAuthProvider(getToken, signOut, user, isSignedIn),
    [getToken, signOut, user, isSignedIn]
  );

  const [resources, routes] = useMemo(() => {
    const isSuperAdmin = getSuperAdminPrivilege(user);
    return [
      getResources({ mode, isSuperAdmin }),
      getRoutes({ isSuperAdmin, mode }),
    ];
  }, [mode, user]);

  return (
    <DevtoolsProvider>
      <Refine
        dataProvider={{
          default: dataProvider(
            getEnvironmentVariable("VITE_API_BASE_URL"),
            globalAxiosInstance
          ),
          propelApi: dataProvider(
            getEnvironmentVariable("VITE_API_BASE_URL"),
            globalAxiosInstance
          ),
        }}
        notificationProvider={useNotificationProvider}
        routerProvider={routerBindings}
        authProvider={authProvider}
        resources={resources}
        options={{
          syncWithLocation: false,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
          breadcrumb: false,
        }}
      >
        <Routes>{routes}</Routes>
        <UnsavedChangesNotifier />
        <DocumentTitleHandler handler={customTitleHandler} />
      </Refine>
      <DevtoolsPanel />
    </DevtoolsProvider>
  );
};

const NOTIFICATION_CONFIG = { pauseOnHover: true, showProgress: true };
function App() {
  const { loaded } = useClerk(); // Clerk uses isLoaded instead of isLoading

  return (
    <BrowserRouter>
      <AppModeContextProvider>
        <ColorModeContextProvider>
          <AntdApp notification={NOTIFICATION_CONFIG}>
            {loaded ? <RefineWrapper /> : <FullScreenLoading />}
          </AntdApp>
        </ColorModeContextProvider>
      </AppModeContextProvider>
    </BrowserRouter>
  );
}

export default App;
