import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const LogoIconSVG = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_114)">
        <path
          d="M450.137 361.244C464.729 346.652 476.304 329.328 484.202 310.263C492.099 291.198 496.163 270.763 496.163 250.127C496.163 229.491 492.099 209.056 484.202 189.991C476.304 170.926 464.729 153.602 450.137 139.01C435.545 124.418 418.222 112.843 399.156 104.946C380.091 97.0488 359.657 92.9841 339.02 92.9841C318.384 92.9841 297.95 97.0488 278.885 104.946C259.819 112.843 242.496 124.418 227.904 139.01L339.021 250.127L450.137 361.244Z"
          fill="black"
        />
        <path
          d="M361.244 50.1168C346.652 35.5247 329.329 23.9496 310.263 16.0525C291.198 8.15531 270.763 4.09069 250.127 4.09069C229.491 4.09069 209.057 8.15531 189.991 16.0525C170.926 23.9496 153.602 35.5247 139.01 50.1168C124.418 64.7088 112.843 82.0321 104.946 101.098C97.0489 120.163 92.9843 140.597 92.9843 161.234C92.9843 181.87 97.0489 202.304 104.946 221.37C112.843 240.435 124.418 257.758 139.01 272.35L250.127 161.234L361.244 50.1168Z"
          fill="black"
        />
        <path
          d="M339.021 72.3401C327.347 60.6665 313.488 51.4064 298.236 45.0887C282.984 38.771 266.636 35.5193 250.127 35.5193C233.618 35.5193 217.271 38.771 202.018 45.0887C186.766 51.4064 172.907 60.6665 161.234 72.3401C149.56 84.0138 140.3 97.8724 133.982 113.125C127.665 128.377 124.413 144.725 124.413 161.234C124.413 177.743 127.665 194.09 133.982 209.342C140.3 224.595 149.56 238.453 161.234 250.127L250.127 161.234L339.021 72.3401Z"
          fill="white"
        />
        <path
          d="M50.1166 139.01C35.5245 153.602 23.9495 170.926 16.0523 189.991C8.15515 209.056 4.09053 229.491 4.09053 250.127C4.09053 270.763 8.15515 291.197 16.0523 310.263C23.9495 329.328 35.5246 346.652 50.1166 361.244C64.7087 375.836 82.032 387.411 101.097 395.308C120.163 403.205 140.597 407.27 161.233 407.27C181.87 407.27 202.304 403.205 221.369 395.308C240.435 387.411 257.758 375.836 272.35 361.244L161.233 250.127L50.1166 139.01Z"
          fill="black"
        />
        <path
          d="M72.34 161.234C60.6663 172.907 51.4063 186.766 45.0885 202.018C38.7708 217.271 35.5191 233.618 35.5191 250.127C35.5191 266.636 38.7708 282.983 45.0885 298.236C51.4063 313.488 60.6663 327.347 72.34 339.02C84.0136 350.694 97.8723 359.954 113.125 366.272C128.377 372.59 144.724 375.841 161.233 375.841C177.742 375.841 194.09 372.59 209.342 366.272C224.595 359.954 238.453 350.694 250.127 339.02L161.233 250.127L72.34 161.234Z"
          fill="white"
        />
        <g filter="url(#filter0_i_3_114)">
          <path
            d="M139.01 450.137C153.602 464.729 170.925 476.304 189.991 484.201C209.056 492.099 229.49 496.163 250.127 496.163C270.763 496.163 291.197 492.099 310.263 484.201C329.328 476.304 346.652 464.729 361.244 450.137C375.836 435.545 387.411 418.222 395.308 399.156C403.205 380.091 407.27 359.657 407.27 339.02C407.27 318.384 403.205 297.95 395.308 278.884C387.411 259.819 375.836 242.496 361.244 227.904L250.127 339.02L139.01 450.137Z"
            fill="black"
          />
        </g>
        <path
          d="M427.914 339.02C439.588 327.347 448.848 313.488 455.165 298.236C461.483 282.983 464.735 266.636 464.735 250.127C464.735 233.618 461.483 217.271 455.165 202.018C448.848 186.766 439.588 172.907 427.914 161.234C416.24 149.56 402.382 140.3 387.129 133.982C371.877 127.664 355.53 124.413 339.02 124.413C322.511 124.413 306.164 127.664 290.912 133.982C275.659 140.3 261.801 149.56 250.127 161.234L339.021 250.127L427.914 339.02Z"
          fill="white"
        />
        <path
          d="M161.234 427.914C172.907 439.587 186.766 448.848 202.018 455.165C217.271 461.483 233.618 464.735 250.127 464.735C266.636 464.735 282.984 461.483 298.236 455.165C313.488 448.848 327.347 439.587 339.021 427.914C350.694 416.24 359.954 402.382 366.272 387.129C372.59 371.877 375.842 355.529 375.842 339.02C375.841 322.511 372.59 306.164 366.272 290.912C359.954 275.659 350.694 261.801 339.021 250.127L250.127 339.02L161.234 427.914Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_3_114"
          x="139.01"
          y="227.904"
          width="268.26"
          height="272.26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3_114"
          />
        </filter>
        <clipPath id="clip0_3_114">
          <rect width="500" height="500" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogoIcon = (
  props: Partial<CustomIconComponentProps> & { spin?: boolean }
) => <Icon component={LogoIconSVG} {...props} spin={props.spin} />;
