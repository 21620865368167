import { Spin, theme } from "antd";

export const FullScreenLoading = () => {
  const { token } = theme.useToken();

  return (
    <Spin
      size="large"
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: token.colorBgLayout,
      }}
    />
  );
};
